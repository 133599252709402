import { CallCalling, Direct } from "iconsax-react";

const ContactDetails = [
  {
    title: "Phone Number",
    icon: <CallCalling />,
    number: "+41615340029",
    link: "tel:+41615340029",
  },
  {
    title: "Email Address",
    icon: <Direct />,
    number: "info@mabtree.com",
    link: "mailto:info@mabtree.com",
  },
];

export default ContactDetails;
