import { Col, Row } from "antd";
import React from "react";
import OurPipeline from "../../assets/images/mabtree-pipeline-1.svg";
import OurPipelineImage from "../../assets/images/mabtree-pipeline.svg";
import { Link } from "react-router-dom";
import { ArrowRight } from "iconsax-react";
import CTA from "../../Components/CTA/CTA";
import AssetPath from "../../AssetPath/AssetPath";
import PageTitle from "../../Components/PageTitle/PageTitle";

export default function Pipeline() {
  return (
    <>
      <PageTitle
        mainTitle="Our Product"
        longTitle="Pipeline"
        breadcrumbItems={[{ name: "Pipeline", path: "/pipeline" }]}
        image={AssetPath.banner.banner5}
      />

      <section className="spacing bg-light-gray">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="middle"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div
                className="highlight-title text-left mb-4"
                data-aos="fade-right"
              >
                <h2 className="text-capitalize mb-3">Pipeline</h2>
                <p>
                  Our unique platforms generate a vast repertoire of fully human
                  affinity-matured antibodies and their antibody drug conjugate
                  against challenging targets.
                </p>
                <p>
                  We are developing therapeutics designed to modulate the immune
                  system, to overcome cancer immune tolerance and escape. We are
                  also developing therapeutics designed to treat autoimmune
                  diseases.
                </p>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div
                className="partnering-image text-center mt-5 px-lg-4"
                data-aos="fade-up"
              >
                <img
                  src={AssetPath.images.researchandInnovation}
                  alt="partnering with mAbTree Biologics"
                  className="w-100"
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="spacing">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="middle"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 24 }}
              lg={{ span: 24 }}
            >
              <div
                className="highlight-title text-center mb-4"
                data-aos="fade-right"
              >
                <h2 className="text-capitalize mb-3">Our Pipeline</h2>
                <p>
                  We are building a rich pipeline of assets in two main
                  therapeutic spaces – immune diseases and immune oncology.
                </p>
                <div className="card mb-3 p-2 border-radius">
                  <img
                    src={OurPipeline}
                    alt="Product Pipeline"
                    className="w-100"
                  />
                </div>

                <div className="card mb-3 p-2 border-radius">
                  <img
                    src={OurPipelineImage}
                    alt="Product Pipeline"
                    className="w-100"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <CTA PageLink={"/contact"} />
    </>
  );
}
