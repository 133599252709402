import React from "react";
import "./Header.scss";
import { Link } from "react-router-dom";
import { Row, Col, BackTop, Divider } from "antd";
import { ArrowUp2 } from "iconsax-react";
import "./Footer.scss";
import ContactDetails from "./ContactDetails";
import FooterLinks from "./FooterLinks";
import AssetPath from "../AssetPath/AssetPath";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <section className="footer">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 22, lg: 22 },
              { xs: 0, sm: 16, md: 24, lg: 24 },
            ]}
            className=" mt-4 pb-3"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <img
                className="brand"
                src={AssetPath.brand.mabtreeLogoDark}
                alt="mAbTree Biologics"
              />

              <div className="mabtree-helpline">
                {ContactDetails.map((v, i) => (
                  <div className="list position-relative" key={i}>
                    <div className="icon position-absolute">{v.icon}</div>
                    <div className="details">
                      <span className="font-6 d-block text-black">
                        {v.title}
                      </span>
                      <a className="d-block font-weight-bold" href={v.link}>
                        {v.number}
                      </a>
                    </div>
                  </div>
                ))}
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 5 }}
              lg={{ span: 5 }}
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <h4 className="mb-2">mAbTree Biologics</h4>
              <div className="links">
                <ul>
                  {FooterLinks.mabtreeLinks.map((v, i) => (
                    <li key={i}>
                      <Link to={v.link}>{v.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 5 }}
              lg={{ span: 5 }}
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <h4 className="mb-2">Research & Innovation</h4>
              <div className="links">
                <ul>
                  {FooterLinks.primaryLinks.map((v, i) => (
                    <li key={i}>
                      <Link to={v.link}>{v.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>

            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 5 }}
              lg={{ span: 5 }}
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              <h4 className="mb-2">Privacy Info</h4>
              <div className="links">
                <ul>
                  {FooterLinks.privacyLinks.map((v, i) => (
                    <li key={i}>
                      <Link to={v.link}>{v.title}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
          </Row>
          <Divider />
          <div className="text-center mt-4">
            <div className="socialmedia">
              <ul className="d-flex align-items-center justify-content-center">
                {FooterLinks.socialMedia.map((v, i) => (
                  <li key={i}>
                    <a href={v.link} target="_blank">
                      <img src={v.icon} alt={v.title} />
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <span className="font-4 text-black">
              Copyright © {currentYear} mAbTree Biologics. All Rights Reserved
            </span>
          </div>
        </div>
      </section>
      <BackTop>
        <div className="back-to-top">
          <ArrowUp2 />
        </div>
      </BackTop>
    </>
  );
}
