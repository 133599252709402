import React from "react";
import { Row, Col, Collapse } from "antd";
import "./FAQ.scss";
import PageTitle from "../../Components/PageTitle/PageTitle";
import AssetPath from "../../AssetPath/AssetPath";

const { Panel } = Collapse;
function callback(key) {
  console.log(key);
}

const collapseItem = [
  {
    key: 1,
    title: "What is mAbTree Biologics?",
    desc: "mAbTree Biologics is a therapeutic antibody company that focuses on producing high quality, cost-effective human monoclonal antibodies (mAbs) in the fields of Oncology and Immunology. The company's goal is to make these antibodies accessible to patients globally.",
  },
  {
    key: 2,
    title: "What is the vision of mAbTree Biologics?",
    desc: "The vision of mAbTree Biologics is to become a global biopharmaceutical company that drives the development of therapeutic human antibodies.",
  },
  {
    key: 3,
    title: "What is the core purpose of mAbTree Biologics?",
    desc: "The core purpose of mAbTree Biologics is to create and develop novel and targeted antibodies with the aim of improving the lives of patients with cancer and autoimmune disorders.",
  },
  {
    key: 4,
    title: "When was mAbTree Biologics founded?",
    desc: "mAbTree Biologics was founded in 2019.",
  },
  {
    key: 5,
    title: "Where is mAbTree Biologics located?",
    desc: "mAbTree Biologics is based in Switzerland and India.",
  },
  {
    key: 6,
    title:
      "What is the focus of mAbTree Biologics' research and development efforts?",
    desc: "mAbTree Biologics is focused on developing therapeutics in the areas of immune diseases and immune oncology. This includes creating antibodies that can modulate the immune system to address challenges in cancer immune tolerance and autoimmune diseases.",
  },
  {
    key: 7,
    title: "What is mAbTree Biologics' pipeline of assets?",
    desc: "mAbTree Biologics is building a pipeline of assets in immune diseases and immune oncology. The pipeline includes programs such as MT0001 and MT0002 for oncology, and MT0003 for Multiple Sclerosis and MT0004 for Psoriasis in the field of Immunology.",
  },
  {
    key: 8,
    title: "What is mAbTree Biologics' approach to product discovery?",
    desc: "mAbTree Biologics aims to revolutionize cancer care by creating and developing targeted innovative antibody-based products. Their approach involves combining innovative antibody platform technologies with a strong understanding of disease biology to create novel and differentiated products.",
  },
  {
    key: 9,
    title:
      "What are innovative antibodies and how does mAbTree Biologics utilize them?",
    desc: "Innovative antibodies refer to antibodies that are developed using advanced techniques to target specific disease-related factors. mAbTree Biologics connects their innovative antibody platform technologies with robust target and disease biology knowledge to create unique products for cancer and autoimmune disorder treatments.",
  },
  {
    key: 10,
    title: "How does mAbTree Biologics collaborate with partners?",
    desc: "mAbTree Biologics recognizes the value of partnering with like-minded organizations to develop the next generation of antibody therapeutics. They collaborate to access third-party technologies and antibody-based products, with the goal of transforming the treatment landscape for cancer and autoimmune disorders.",
  },
  {
    key: 11,
    title:
      "Are there specific disease areas that mAbTree Biologics focuses on in its partnerships?",
    desc: "mAbTree Biologics considers partnering opportunities for disease areas where antibody-based therapies could be effective, including central nervous system conditions and infectious diseases.",
  },
  {
    key: 12,
    title:
      "What sets mAbTree Biologics apart in the field of therapeutic antibodies?",
    desc: "mAbTree Biologics stands out by its dedication to producing high-quality, cost-effective therapeutic antibodies in Oncology and Immunology. Their focus on innovation, research, and partnerships helps drive the development of novel treatments for cancer and autoimmune disorders.",
  },
];

export default function FAQ() {
  return (
    <>
      <PageTitle
        mainTitle="Frequently Asked"
        longTitle="Questions"
        breadcrumbItems={[{ name: "Frequently Asked Questions", path: "/faq" }]}
        image={AssetPath.banner.banner2}
      />
      <section className="faq spacing bg-light-gray">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
              data-aos="fade-up"
            >
              <div className="highlight-title contact-card " data-aos="fade-up">
                <Collapse ghost onChange={callback} expandIconPosition="right">
                  {collapseItem.map((list) => (
                    <Panel header={list.title} key={list.key}>
                      <p>{list.desc}</p>
                    </Panel>
                  ))}
                </Collapse>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
