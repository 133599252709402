import React from "react";
import { Row, Col, Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import "./PageTitle.scss";

export default function PageTitle({
  image,
  mainTitle,
  longTitle,
  breadcrumbItems,
  mainDesc,
}) {
  const items = [
    { name: "mAbTree Biologics AG", path: "/" },
    ...breadcrumbItems,
  ];
  return (
    <>
      <section className="breadcrum-placehlder bg-patterns position-relative">
        <div className="image position-absolute">
          <img src={image} alt={mainTitle + " " + longTitle} />
        </div>
        <div className="container">
          <div className="content">
            <div>
              <h1 className="text-white">
                {mainTitle} <span className="text-green">{longTitle}</span>
              </h1>
              {mainDesc ? <p className="text-white">{mainDesc}</p> : ""}

              <div className="page-path">
                {items.length > 0 ? (
                  <Breadcrumb>
                    {items.map((item, i) => (
                      <Breadcrumb.Item key={i}>
                        <Link to={item.path}>{item.name}</Link>
                      </Breadcrumb.Item>
                    ))}
                  </Breadcrumb>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
