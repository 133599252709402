import { Col, Row } from "antd";
import React from "react";
import scientistImage from "../../assets/images/illustration/scientist.svg";
import scientist2Image from "../../assets/images/illustration/scientist2.svg";
import scientist3Image from "../../assets/images/illustration/scientist3.svg";
import "./Careers.scss";
import AssetPath from "../../AssetPath/AssetPath";
import CTA from "../../Components/CTA/CTA";
import PageTitle from "../../Components/PageTitle/PageTitle";

const jobList = [
  {
    title: "Scientist - Immunology / Biotechnology",
    image: [AssetPath.images.immunologyBioscience],
    location: "Hyderabad",
    qualification: "MSc",
  },
  {
    title: "Scientist - Microbiology",
    image: [AssetPath.images.Microbiology],
    location: "Hyderabad",
    qualification: "MSc",
  },
  {
    title: "Junior Scientist - Biochemistry / Biotechnology",
    image: [AssetPath.images.Biotechnology],
    location: "Hyderabad",
    qualification: "BSc/BTech",
  },
];

export default function Careers() {
  return (
    <>
      <PageTitle
        mainTitle="Careers"
        breadcrumbItems={[{ name: "Careers", path: "/careers" }]}
        image={AssetPath.banner.banner6}
      />
      <section className="spacing bg-white">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="middle"
            justify="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              data-aos="fade-up"
            >
              <div
                className="highlight-title width-80 text-left mb-4"
                data-aos="fade-up"
              >
                <h2 className="text-capitalize mb-2">
                  Become a Part of Our Team
                </h2>
                <p className="mt-3">
                  Here's your opportunity to join a unique, global company with
                  an incredible, life-changing mission
                </p>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              data-aos="fade-up"
            >
              <div className="text-center mb-4" data-aos="fade-up">
                <img
                  src={AssetPath.images.career}
                  alt="Our Team"
                  className="w-100"
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>
      <section className="current-jobs spacing bg-light-gray">
        <div className="container">
          <div className="text-center text-black" data-aos="fade-up">
            <h3 className=" mb-4">Current Jobs at mAbTree Biologics</h3>
          </div>
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
          >
            {jobList.map((v, index) => (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                data-aos="fade-up"
              >
                <div className="job-list text-left mb-2" data-aos="fade-up">
                  <div className="image">
                    <img src={v.image} alt={v.title} className="w-100" />
                  </div>
                  <div className="job-details">
                    <h2>{v.title}</h2>
                    <div className="qualification font-4">
                      Qualifications: <span>{v.qualification}</span>{" "}
                    </div>
                    <div className="qualification font-4">
                      Location: <span>{v.location}</span>{" "}
                    </div>
                  </div>
                </div>
              </Col>
            ))}
          </Row>
        </div>
      </section>
      <CTA
        title="Apply for the Job in mAbTree Biologics"
        description="To apply for the above jobs Please share your resume and contact information to hr@mabtree.com"
        linkText="Apply Now"
        PageLink={"/contact"}
      />
    </>
  );
}
