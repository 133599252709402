import { Button } from "antd";
import React, { Component } from "react";
import { ArrowUpRight } from "feather-icons-react";

class ButtonBox extends Component {
  componentDidMount() {
    this.props.refs?.(this);
  }

  componentWillUnmount() {
    this.props.refs?.(null);
  }

  onClick = () => {
    if (!this.props.loader) {
      this.props.onClick?.();
    }
  };

  render() {
    const { children, type, loader, style, label, rightIcon, size } =
      this.props;

    return (
      <Button
        style={style}
        onClick={this.onClick}
        type={type || "primary"}
        loading={loader}
        size={size || "middle"}
      >
        {label}
        {children}
        {rightIcon ? <ArrowUpRight className="ms-2" size={17} /> : ""}
      </Button>
    );
  }
}

export default ButtonBox;
