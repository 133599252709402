import { Col, Row } from "antd";
import React from "react";
import { Link, useHistory } from "react-router-dom";
import PageTitle from "../../Components/PageTitle/PageTitle";
import AssetPath from "../../AssetPath/AssetPath";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";

export default function PageNotFound() {
  const history = useHistory();

  return (
    <>
      <PageTitle
        mainTitle="Page"
        longTitle="Not Found"
        breadcrumbItems={[{ name: "Page Not Found", path: "/" }]}
        image={AssetPath.banner.banner6}
      />

      <section className="faq spacing bg-light-gray">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 18 }}
              lg={{ span: 18 }}
              data-aos="fade-up"
            >
              <div
                className="highlight-title contact-card text-center"
                data-aos="fade-up"
              >
                <h1>404 Error - Page Not Found</h1>
                <p>
                  The page you are looking for could not be found. Please check
                  the URL or go back to the homepage.
                </p>

                <ButtonBox
                  type="primary"
                  rightIcon
                  label="Go back to Home"
                  onClick={() => history.push("/")}
                />
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
