import React from "react";
import ReactDOM from "react-dom";
import { Formik, Field, Form } from "formik";
import "./Contact.scss";
import { message } from "antd";
import { ArrowUpRight } from "feather-icons-react";

import { useHistory } from "react-router-dom";
import apiClient from "../../Actions/apiClient";

export default function ContactForm({ props }) {
  const history = useHistory();
  const onSend = async (values, actions) => {
    try {
      const { data } = await apiClient.post("contactus/add", values);
      if (data?.result) {
        message.success(
          "Thanks for contacting us! Our team will reach out to you shortly"
        );
        history.push("/");
      }
    } catch (error) {
      // Handle error
      console.error(error);
      message.error("An error occurred. Please try again later.");
    }
  };
  return (
    <div className="contact-form">
      <Formik
        initialValues={{
          name: "",
          email: "",
          company: "",
          phone: "",
          subject: "",
          message: "",
        }}
        onSubmit={onSend}
      >
        <Form>
          <Field name="name" required placeholder="Full Name *" type="text" />
          <Field
            name="email"
            required
            placeholder="Email Address *"
            type="email"
          />
          <Field name="company" placeholder="Company Name (Optional)" />
          <Field
            name="phone"
            placeholder="Phone Number (Optional)"
            type="phone"
          />
          <Field name="subject" placeholder="Subject" />
          <Field
            as="textarea"
            placeholder="Write your message"
            name="message"
            type="text"
          />

          <button type="submit" className="ant-btn ant-btn-secondary">
            Submit <ArrowUpRight size={16} className="ms-2" />
          </button>
          {/* <ButtonBox
            label="Submit"
            rightIcon
            className="ant-btn ant-btn-primary btn-lg w-100"
            type="submit"
          /> */}
        </Form>
      </Formik>
    </div>
  );
}
