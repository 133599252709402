import React from "react";
import { Row, Col } from "antd";
import "./Home.scss";
import { Link, useHistory } from "react-router-dom";

import biologicImage from "../../assets/images/icons/biosimlar.svg";
import global from "../../assets/images/icons/global.svg";
import money from "../../assets/images/icons/money.svg";
import partneringImg from "../../assets/images/partnering.png";
import { ArrowRight } from "iconsax-react";
import HomeBanner from "./HomeBanner";
import CTA from "../../Components/CTA/CTA";
import AssetPath from "../../AssetPath/AssetPath";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";

const biosimilars = [
  {
    mainTitle: "100+ Biologic Products",
    icon: [biologicImage],
    description:
      "Over 100+ biologic products will lose patent or other protections by 2022",
  },
  {
    mainTitle: "$250 Billion Savings",
    icon: [money],
    description:
      "Amount of possible savings 11 likeliest Biologics could create between 2014 and 2024 in the US",
  },
  {
    mainTitle: "Accessible Countries",
    icon: [global],
    description:
      "More than 35+ countries are using Biological medicine world wide",
  },
];

export default function Home() {
  const history = useHistory();
  return (
    <React.Fragment>
      {/* <!--Main Slider section starts--> */}
      <HomeBanner />

      <section className="spacing unlock-opportunities">
        <div className="container">
          <div className="mb-5">
            <h2 className="text-center text-primary" data-aos="fade-up">
              Unlocking Opportunities with Biologics
            </h2>
          </div>
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
          >
            {biosimilars.map((v, index) => (
              <Col
                xs={{ span: 24 }}
                sm={{ span: 12 }}
                md={{ span: 8 }}
                lg={{ span: 8 }}
                key={index}
                data-aos="fade-up"
              >
                <div className="details">
                  <img src={v.icon} alt={v.mainTitle} className="mb-3" />
                  <h3 className="mb-2">{v.mainTitle}</h3>
                  <p className="text-gray font-weight-normal">
                    {v.description}
                  </p>
                </div>
              </Col>
            ))}
          </Row>
          <div className="text-center" data-aos="fade-up">
            <ButtonBox
              label="Know More"
              rightIcon
              size="large"
              type="secondary"
              onClick={() => history.push("/partnering")}
            />
          </div>
        </div>
      </section>

      <section className="our-vision bg-primary spacing">
        <div className="overlay" data-aos="fade-in" />
        <div className="container">
          <div className="content mb-4" data-aos="fade-in">
            <span className="d-block text-uppercase text-white mb-2">
              Our vision
            </span>
            <h3 className="text-green h2 mb-3">
              Global Therapeutic Antibody Leaders
            </h3>
            <p className="lead text-white">
              Our vision is to be a global biopharmaceutical company, driving
              the development of therapeutic human antibodies.
            </p>
          </div>
          <div className="text-left" data-aos="fade-in">
            <ButtonBox
              type="secondary"
              size="large"
              label="Know Our Purpose"
              rightIcon
              onClick={() => history.push("/about-us")}
            />
          </div>
        </div>
      </section>

      <section className="video-section"></section>

      <section className="partnering spacing">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              data-aos="fade-up"
            >
              <div className="pl-md-5 highlight-title mb-2 z-index">
                <div data-aos="fade-up">
                  <span className="d-block text-primary mb-2 text-uppercase">
                    Partnering
                  </span>
                  <h2 className="mb-3">
                    Advancing Antibody Therapies for Cancer and Autoimmune
                    Diseases
                  </h2>
                  <p>
                    By partnering to enable access to third-party technologies
                    and antibody-based products, we facilitate the development
                    of first-in-class or best-in-class antibody therapies and
                    work to transform the future of cancer and auto-immune
                    disorder treatment.
                  </p>
                  <div className="mt-4" data-aos="fade-up">
                    <ButtonBox
                      label="Partner With Us"
                      rightIcon
                      size="large"
                      onClick={() => history.push("/partnering")}
                    />
                  </div>
                </div>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              data-aos="fade-up"
            >
              <img
                src={AssetPath.images.partners}
                alt="mAbtree Partners"
                className="w-100"
              />
            </Col>
          </Row>
        </div>
      </section>

      <CTA PageLink={"/contact"} />
    </React.Fragment>
  );
}
