import React, { useRef } from "react";
import { Button, Carousel } from "antd";
import { Link, useHistory } from "react-router-dom";
import "./HomeBanner.scss";
import { ArrowLeft, ArrowRight } from "iconsax-react";
import ButtonBox from "../../Components/ButtonBox/ButtonBox";

const bannerContent = [
  {
    title: "Mabtree Biologics AG: Pioneering Healthcare Innovations",
    decription:
      "Mabtree Biologics AG, based in Basel's Stucki Tech Park, innovates in monoclonal antibody therapies to address critical healthcare gaps.",
    class: "banner5",
  },
  {
    title: "Innovating Human Antibodies for Unmet Needs",
    decription:
      "Our vision is to innovate and develop proprietary human monoclonal antibodies to treat disease conditions with high unmet needs",
    class: "banner1",
  },
  {
    title: "Revolutionizing Treatment with Monoclonal Antibodies",
    decription:
      "Transforming scientific breakthroughs into treatments for challenging diseases, offering new hope to those in need.",
    class: "banner2",
  },
  {
    title: "Bridging the Gap in Healthcare",
    decription:
      "Developing crucial monoclonal antibody therapies to improve quality of life for patients with previously untreatable conditions",
    class: "banner3",
  },
  {
    title: "Innovating for a Healthier Tomorrow",
    decription:
      "Pioneering proprietary antibodies to address unmet medical needs, leading the way to a future of advanced, effective healthcare solutions.",
    class: "banner4",
  },
];

export default function HomeBanner() {
  const history = useHistory();

  const carouselRef = useRef();
  // prev and next function
  const next = () => {
    carouselRef.current.next();
  };
  const prev = () => {
    carouselRef.current.prev();
  };

  return (
    <div className="mabtree-carousel position-relative">
      <Carousel
        autoplay="true"
        autoplaySpeed={4000}
        infinite="true"
        effect="fade"
        speed={1000}
        ref={carouselRef}
        dotPosition="right"
      >
        {bannerContent.map((data, i) => (
          <section className={`main-banner position-relative ${data.class}`}>
            <div className="container ">
              <div className="banner-height d-flex align-items-center justify-content-left">
                <div className="content">
                  <h1 className="text-white">{data.title}</h1>
                  <p className="text-white my-3">{data.decription}</p>
                  <ButtonBox
                    rightIcon
                    type="secondary"
                    label="Learn More"
                    onClick={() => history.push("/about-us")}
                  />
                </div>
              </div>
            </div>
            <div className="banner-gradient" />
          </section>
        ))}
      </Carousel>
      <div className="prev-next-nav d-flex text-white ">
        <div onClick={prev} className="me-2 cursor-pointer">
          <ArrowLeft size={16} className="me-2" />
          Prev
        </div>
        <div onClick={next} className="cursor-pointer">
          Next
          <ArrowRight size={16} className="ms-2" />
        </div>
      </div>
    </div>
  );
}
