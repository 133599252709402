import AssetPath from "../AssetPath/AssetPath";

const FooterLinks = {
  socialMedia: [
    {
      title: "Facebook",
      icon: AssetPath.icons.facebookIcon,
      link: "https://www.facebook.com/mAbTreeBiologics/",
    },
    {
      title: "Linkedin",
      icon: AssetPath.icons.linkedinIcon,
      link: "https://www.linkedin.com/company/mabtree-biologics",
    },
  ],
  mabtreeLinks: [
    {
      title: "About Us",
      link: "/about-us",
    },
    {
      title: "Partnering",
      link: "/partnering",
    },
    {
      title: "FAQ",
      link: "/faq",
    },
    {
      title: "Contact Us",
      link: "/contact",
    },
  ],
  privacyLinks: [
    {
      title: "Privacy",
      link: "/privacy-policy",
    },
    {
      title: "Terms & Conditions",
      link: "/terms-and-conditions",
    },
  ],
  primaryLinks: [
    {
      title: "Research & Innovation",
      link: "/research-and-innovation",
    },
    {
      title: "Pipeline",
      link: "/pipeline",
    },
    {
      title: "Careers",
      link: "/careers",
    },
  ],
};

export default FooterLinks;
