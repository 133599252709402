import React from "react";
import { Link, useHistory } from "react-router-dom";
import { Row, Col } from "antd";
import { ArrowRight } from "iconsax-react";
import AssetPath from "../../AssetPath/AssetPath";
import ButtonBox from "../ButtonBox/ButtonBox";
import "./CTA.scss";

export default function CTA({ title, description, linkText, PageLink }) {
  const history = useHistory();
  return (
    <section className="cta-bar bg-green py-5 position-relative overflow-hidden">
      <div className="container">
        <Row
          justify="middle"
          gutter={[22, 24]}
          className=" mt-4 pb-3"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          <Col
            xs={{ span: 24 }}
            sm={{ span: 24 }}
            md={{ span: 24 }}
            lg={{ span: 24 }}
          >
            <div className="text-black" data-aos="fade-up">
              <h2 className="mb-3 text-white">
                {title ? title : "Join the Evolution of Treatment"}
              </h2>
              <p className="mb-3 text-white">
                {description
                  ? description
                  : "Partner with us to revolutionize healthcare through groundbreaking human antibody innovation."}
              </p>
              <ButtonBox
                size="large"
                type="default"
                label={linkText ? linkText : "Explore Our Innovations"}
                onClick={() => history.push(PageLink ? PageLink : "/")}
                rightIcon
              />
            </div>
          </Col>
          {/* <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 4 }}
            lg={{ span: 4 }}
          >
            <div className="icon">
              <img
                src={AssetPath.brand.mabtreeIcon}
                className="w-100"
                alt="Join the Evolution of Treatment with mAbTree Biologics"
              />
            </div>
          </Col> */}
        </Row>
      </div>

      <div className="mabtree-pattern position-absolute bottom-0 end-0" />
    </section>
  );
}
