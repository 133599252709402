import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Header.scss";
import { HambergerMenu, CloseCircle } from "iconsax-react";
import AssetPath from "../AssetPath/AssetPath";
import ButtonBox from "../Components/ButtonBox/ButtonBox";

const menuItems = [
  {
    title: "Home",
    link: "/",
  },
  {
    title: "About Us",
    link: "/about-us",
    // submenu: [
    //   {
    //     title: "Our Vision",
    //     link: "/aboutus/our-purpose",
    //   },
    //   {
    //     title: "Our Vision",
    //     link: "/aboutus/our-purpose",
    //   },
    //   {
    //     title: "Our Purpose",
    //     link: "/aboutus/our-purpose",
    //   },
    //   {
    //     title: "History",
    //     link: "/aboutus/our-history",
    //   },
    //   {
    //     title: "Our Team",
    //     link: "/aboutus/our-team",
    //   },
    // ],
  },
  {
    title: "Partnering",
    link: "/partnering",
  },
  {
    title: "Research & Innovation",
    link: "/research-and-innovation",
    // submenu: [
    //   {
    //     title: "Innovative Antibodies",
    //     link: "/research-and-innovation/antibodies",
    //   },
    //   {
    //     title: "Biosimilars",
    //     link: "/research-and-innovation/biosimilars",
    //   },
    // ],
  },
  {
    title: "Pipeline",
    link: "/pipeline",
  },
  {
    title: "Careers",
    link: "/careers",
  },
];

export default function Header() {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const history = useHistory();

  const handleNavigate = () => {
    history.push("/contact");
    setIsMenuActive(false);
  };

  const MenuItem = ({ title, link, submenu }) => {
    return (
      <li>
        <Link
          to={link}
          className={`${(submenu && "parent-submenu") || "child-submenu"}`}
          onClick={() => setIsMenuActive(false)}
        >
          {title}
        </Link>
        {submenu && submenu.length > 0 && (
          <ul>
            {submenu.map((subitem, index) => (
              <MenuItem key={index} {...subitem} />
            ))}
          </ul>
        )}
      </li>
    );
  };

  const NavigationMenu = ({ menuItems }) => {
    return (
      <ul>
        {menuItems.map((menuItem, index) => (
          <MenuItem key={index} {...menuItem} />
        ))}
      </ul>
    );
  };

  return (
    <React.Fragment>
      {/* <!--header navigation--> */}
      <div className="header">
        <nav className="container">
          <div className="flex-menu">
            {/* <!--Logo --> */}
            <div className="brand">
              <Link to="/">
                <img
                  src={AssetPath.brand.mabtreeLogoDark}
                  className="dark-logo"
                  alt="mabtree Biologics"
                />
                <img
                  src={AssetPath.brand.mabtreeLogoWhite}
                  className="white-logo"
                  alt="mabtree Biologics"
                />
              </Link>
            </div>
            <div
              className={`right-menu flex-design ${
                isMenuActive ? "active" : ""
              }`}
            >
              <NavigationMenu menuItems={menuItems} />
              <div className="cta">
                <ButtonBox
                  rightIcon
                  size="large"
                  label="Contact Us"
                  onClick={handleNavigate}
                />
              </div>
            </div>
            <div
              className="hamburger-menu"
              onClick={() => setIsMenuActive(!isMenuActive)}
            >
              {isMenuActive ? <CloseCircle /> : <HambergerMenu />}
            </div>
          </div>
        </nav>
      </div>
      {/* <!--header navigation ends--> */}
    </React.Fragment>
  );
}
