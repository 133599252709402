import { Col, Row } from "antd";
import { Field, Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { useEffect } from "react";
import PageTitle from "../../Components/PageTitle/PageTitle";
import AssetPath from "../../AssetPath/AssetPath";
import apiClient from "../../Actions/apiClient";

export default function Login() {
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (localStorage.getItem("MABTREE_USER")) {
      history.push("/app/contactus");
    }
  }, []);

  const onLogin = (values) => {
    apiClient.post("auth", values).then(({ data }) => {
      if (data?.result) {
        dispatch({ type: "GET_USER", payload: data.result });
        localStorage.setItem("MABTREE_USER", data.token);
        history.push("/app/contactus");
      }
    });
  };
  return (
    <>
      <PageTitle
        mainTitle="Login"
        longTitle="as a Admin"
        breadcrumbItems={[{ name: "Login", path: "/login" }]}
        image={AssetPath.banner.banner2}
      />
      <section className="contact-support spacing light-background">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
            align="middle"
            justify="center"
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 10 }}
              lg={{ span: 10 }}
              data-aos="fade-up"
            >
              <div class="highlight-title" data-aos="fade-up">
                <h4>Login</h4>
                <p>Enter email and password to access Admin portal.</p>
                <div className="contact-form">
                  <Formik
                    initialValues={{
                      email: "",
                      password: "",
                    }}
                    onSubmit={async (values) => {
                      onLogin(values);
                    }}
                  >
                    <Form>
                      <Field
                        name="email"
                        required
                        placeholder="Email Address *"
                        type="email"
                      />
                      <Field
                        name="password"
                        required
                        placeholder="Password"
                        type="password"
                      />

                      <button
                        className="btn btn-warning btn-lg w-100"
                        type="submit"
                      >
                        Login
                      </button>
                    </Form>
                  </Formik>
                </div>
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
}
