import { Col, Row } from "antd";
import React from "react";
import PartneringImage from "../../assets/images/partnering-with-us.png";
import { Link } from "react-router-dom";
import { ArrowRight } from "iconsax-react";
import CTA from "../../Components/CTA/CTA";
import AssetPath from "../../AssetPath/AssetPath";
import PageTitle from "../../Components/PageTitle/PageTitle";

export default function Partnering() {
  return (
    <>
      <PageTitle
        mainTitle="Partnering"
        longTitle="with mAbTree Biologics"
        breadcrumbItems={[{ name: "Partnering", path: "/partnering" }]}
        image={AssetPath.banner.banner3}
      />
      <section className="spacing bg-white position-relative">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              data-aos="fade-up-right"
            >
              <div
                className="highlight-title text-left mb-4"
                data-aos="fade-up"
              >
                <h2 className="text-capitalize mb-3">Partnering</h2>
                <p>
                  Our quest to develop the next generation of antibody
                  therapeutics recognizes the inherent value of working with
                  resourceful and like-minded partners.Our quest to develop the
                  next generation of antibody therapeutics recognizes the
                  inherent value of working with resourceful and like-minded
                  partners.
                </p>
                <p>
                  By partnering to enable access to third-party technologies and
                  antibody-based products, we facilitate the development of
                  first-in-class or best-in-class antibody therapies and work to
                  transform the future of cancer and auto-immune disorder
                  treatment.
                </p>
                <p>
                  In addition to these, we may consider partnership
                  opportunities to address any disease area where antibody-based
                  therapies may be effective, including central nervous system
                  conditions and infectious diseases.
                </p>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              data-aos="fade-up"
            >
              <div className="partnering-image text-center" data-aos="fade-up">
                <img
                  src={PartneringImage}
                  alt="partnering with mAbTree Biologics"
                  className="w-100"
                />
              </div>
            </Col>
          </Row>
        </div>
        {/* <img
          src={AssetPath.pattern.immuneCircle}
          alt="Immune circle pattern"
          className="position-absolute end-0 bottom-0"
        /> */}
        <div className="circle-pattern position-absolute end-150 bottom-150" />
      </section>
      <CTA PageLink={"/contact"} />
    </>
  );
}
