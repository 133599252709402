import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import "./Header.scss";
import mabtreeLogo from "../assets/images/brand/mabtreelogo.svg";
import {
  HambergerMenu,
  CloseCircle,
  Whatsapp,
  ArrowRight,
} from "iconsax-react";
import ButtonBox from "../Components/ButtonBox/ButtonBox";

const menuItems = [
  {
    title: "Contact Us",
    link: "/app/contactus",
  },
];

export default function Header() {
  const history = useHistory();
  const [isMenuActive, setIsMenuActive] = useState(false);
  const MenuItem = ({ title, link, submenu }) => {
    return (
      <li>
        <Link
          to={link}
          className={`${(submenu && "parent-submenu") || "child-submenu"}`}
          onClick={() => setIsMenuActive(false)}
        >
          {title}
        </Link>
        {submenu && submenu.length > 0 && (
          <ul>
            {submenu.map((subitem, index) => (
              <MenuItem key={index} {...subitem} />
            ))}
          </ul>
        )}
      </li>
    );
  };

  const NavigationMenu = ({ menuItems }) => {
    return (
      <ul>
        {menuItems.map((menuItem, index) => (
          <MenuItem key={index} {...menuItem} />
        ))}
      </ul>
    );
  };

  const onLogout = () => {
    localStorage.removeItem("MABTREE_USER");
    history.push("/");
  };

  return (
    <React.Fragment>
      {/* <!--header navigation--> */}
      <div className="header">
        <nav className="container">
          <div className="flex-menu">
            {/* <!--Logo --> */}
            <div className="brand">
              <Link to="/">
                <img
                  src={mabtreeLogo}
                  className="dark-logo"
                  alt="Mabtree Biologics"
                />
              </Link>
            </div>
            <div
              className={`right-menu flex-design ${
                isMenuActive ? "active" : ""
              }`}
            >
              <NavigationMenu menuItems={menuItems} />
              <div className="cta">
                <ButtonBox
                  className="ant-btn ant-btn-primary"
                  onClick={onLogout}
                  rightIcon
                >
                  Logout
                </ButtonBox>
              </div>
            </div>
            <div
              className="hamburger-menu"
              onClick={() => setIsMenuActive(!isMenuActive)}
            >
              {isMenuActive ? <CloseCircle /> : <HambergerMenu />}
            </div>
          </div>
        </nav>
      </div>
      {/* <!--header navigation ends--> */}
    </React.Fragment>
  );
}
