import { Col, Row } from "antd";
import React from "react";
import PipelineImage from "../../assets/images/pipeline-research.png";
import InnovativeAntibodiesImage from "../../assets/images/illustration/innovative-antibodies.svg";
import { Link } from "react-router-dom";
import { ArrowRight } from "iconsax-react";
import CTA from "../../Components/CTA/CTA";
import AssetPath from "../../AssetPath/AssetPath";
import PageTitle from "../../Components/PageTitle/PageTitle";

export default function ResearchInnovation() {
  return (
    <>
      <PageTitle
        mainTitle="mAbTree"
        longTitle="Research & Innovation"
        breadcrumbItems={[
          { name: "Research & Innovation", path: "/research-and-innovation" },
        ]}
        image={AssetPath.banner.banner4}
      />

      <section className="spacing bg-white position-relative overflow-hidden">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="middle"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div
                className="highlight-title text-left mb-4"
                data-aos="fade-up"
              >
                <h2 className="text-capitalize  mb-3">Research & Innovation</h2>
                <p>
                  We are devoted to revolutionizing the care of cancer patients
                  by creating and developing targeted innovative antibody-based
                  products. This commitment is deeply rooted in science.
                </p>
                <p>
                  With a deep knowledge of the power of the human immune system
                  and a proven track record for inventing and engineering novel
                  therapeutic antibodies, we aim to tackle and overcome the
                  challenges in oncology drug development.
                </p>
                <p>
                  Our product discovery connects innovative antibody platform
                  technologies with robust targets and disease biology knowledge
                  to create novel and differentiating products.
                </p>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div
                className="partnering-image text-center position-relative z-index mt-5 px-lg-4"
                data-aos="fade-up"
              >
                <img
                  src={AssetPath.images.researchandInnovation}
                  alt="partnering with mAbTree Biologics"
                  className="w-100"
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="circle-pattern top-150 end-150 position-absolute" />
      </section>
      <section
        className="spacing bg-light-gray position-relative"
        data-aos="fade-up"
      >
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="middle"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div
                className="partnering-image text-center position-relative z-index  px-lg-4"
                data-aos="fade-up"
              >
                <img
                  src={AssetPath.images.innovativeAntibodies}
                  alt="Innovative Antibodies mAbTree Biologics"
                  className="w-100 "
                />
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div
                className="highlight-title text-left mb-4"
                data-aos="fade-up"
              >
                <h2 className="text-capitalize  mb-3">Innovative Antibodies</h2>
                <p>
                  We strive to improve cancer patients’ lives by creating and
                  developing a new generation of innovative antibody-targeted
                  empowered products. This commitment is deeply rooted in
                  science.
                </p>
                <p>
                  In our product discovery, we connect our innovative antibody
                  platform technologies with our robust target and disease
                  biology knowledge to create novel and differentiating
                  products.
                </p>
                <p className="mt-3">
                  We aim to invent, develop and commercialize differentiated
                  antibody-drug conjugates, which can be utilized in a modular
                  fashion to create unique and tailored therapeutics to
                  transform cancer and autoimmune disorder treatments.
                </p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mabtree-pattern  start-0 bottom-0 position-absolute" />
      </section>
      <CTA PageLink={"/contact"} />
    </>
  );
}
