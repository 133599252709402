import AssetPath from "../../AssetPath/AssetPath";

const TeamMember = [
  {
    Title: "Leadership Team",
    leadershipTeam: [
      {
        name: "Andhuvan Raj",
        role: "Founder & Chairman",
        location: "Switzerland",
        image: [AssetPath.leadershipTeam.AndhuvanRaj],
        flag: [AssetPath.flag.Switzerland],
      },
      {
        name: "Dr. Thibaut Denoel",
        role: "Chief Executive Officer",
        location: "Switzerland",
        image: [AssetPath.leadershipTeam.Thibaut],
        flag: [AssetPath.flag.Switzerland],
      },
      {
        name: "Filippo Oliveri",
        role: "Chief Business Officer",
        location: "Switzerland",
        image: [AssetPath.leadershipTeam.filippoOliveri],
        flag: [AssetPath.flag.Switzerland],
      },
      {
        name: "Monica Alexander",
        role: "Managing Director",
        location: "Switzerland",
        image: [AssetPath.leadershipTeam.MonicaAlexander],
        flag: [AssetPath.flag.Switzerland],
      },
      {
        name: "Dr. med. R. Chandran",
        role: "Chief Scientific Advisor",
        location: "Switzerland",
        image: [AssetPath.leadershipTeam.Jayachandran],
        flag: [AssetPath.flag.Switzerland],
      },
      {
        name: "Dr. Sakthi Devi",
        role: "Senior Clinical Pysician",
        location: "Switzerland",
        image: [AssetPath.biosafetyteam.sakthi],
        flag: [AssetPath.flag.Switzerland],
      },
      {
        name: "Dr. Tata Nageswara Rao, PhD",
        role: "Senior Scientific advisor",
        location: "Switzerland",
        image: [AssetPath.leadershipTeam.tataNageswaraRao],
        flag: [AssetPath.flag.Switzerland],
      },
      {
        name: "Chakra Ithayamani",
        role: "Managing Partner",
        location: "Switzerland",
        image: [AssetPath.leadershipTeam.chakra],
        flag: [AssetPath.flag.Switzerland],
      },
      {
        name: "Dr. Chandni Sood",
        role: "Chief Scientific Officer",
        location: "Switzerland",
        image: [AssetPath.leadershipTeam.ChandniSood],
        flag: [AssetPath.flag.Switzerland],
      },
      // {
      //   name: "Werner Lang",
      //   role: "Board of Director",
      //   location: "Germany",
      //   image: [AssetPath.leadershipTeam.WernerLang],
      //   flag: [AssetPath.flag.Germany],
      // },
      {
        name: "Dr. David Viertl",
        role: "Board of Director",
        location: "Switzerland",
        image: [AssetPath.leadershipTeam.David],
        flag: [AssetPath.flag.Switzerland],
      },
      {
        name: "Prof. Origène Nyanguile",
        role: "Senior Scientific Advisor",
        location: "Switzerland",
        image: [AssetPath.leadershipTeam.Origene],
        flag: [AssetPath.flag.Switzerland],
      },
      {
        name: "Arun Prakash",
        role: "Board of Director",
        location: "India",
        image: [AssetPath.leadershipTeam.Arun],
        flag: [AssetPath.flag.India],
      },
      {
        name: "Dr. Massimo Nobile",
        role: "Scientific Advisor",
        location: "Switzerland",
        image: [AssetPath.leadershipTeam.Massimo],
        flag: [AssetPath.flag.Switzerland],
      },
      {
        name: "Veda Bagath Singh",
        role: "Consultant Legal and compliance",
        location: "India ",
        image: [AssetPath.leadershipTeam.Veda],
        flag: [AssetPath.flag.India],
      },
      {
        name: "Darren Taylor",
        role: "Managing Partner",
        location: "United Kingdom",
        image: [AssetPath.leadershipTeam.DarrenTaylor],
        flag: [AssetPath.flag.UnitedKingdom],
      },
    ],
  },
  {
    Title: "Institutional Biosafety Committee Board for mAbtree",
    leadershipTeam: [
      {
        name: "Dr. B. Dinesh Kumar",
        role: "Chairperson",
        image: [AssetPath.biosafetyteam.DineshKumar],
      },

      {
        name: "Dr. Vasanthi Sirugiri",
        role: "Senior Consultant",
        image: [AssetPath.biosafetyteam.VasanthiSirugiri],
      },
      {
        name: "Dr. K. Souris",
        role: "Senior Medical Officer",
        image: [AssetPath.biosafetyteam.Souris],
      },
      {
        name: "Ravindranath Reddy",
        role: "Operations Head",
        image: [AssetPath.biosafetyteam.Ravi],
      },
    ],
  },
  {
    Title: "Scientific Advisors",
    leadershipTeam: [
      // {
      //   name: "Puja Jani",
      //   role: "Project Manager",
      //   location: "United Kingdom",
      //   image: [Puja],
      //   flag: [AssetPath.flag.UnitedKingdom],
      // },
      {
        name: "Chenthoori Pugazendhi",
        role: "Patent Attorny",
        location: "India",
        image: [AssetPath.advisorteam.ChenthooriPugazendhi],
        flag: [AssetPath.flag.India],
      },
      // {
      //   name: "Alphonse Louisa",
      //   role: "Administration",
      //   location: "Switzerland",
      //   image: [alphonseLouisa],
      //   flag: [AssetPath.flag.Switzerland],
      // },
      {
        name: "Shivani Verma",
        role: "Scientist",
        location: "India",
        image: [AssetPath.advisorteam.shivani],
        flag: [AssetPath.flag.India],
      },
      {
        name: "Priyanka Reddy",
        role: "Scientist",
        location: "India",
        image: [AssetPath.advisorteam.Priyanka],
        flag: [AssetPath.flag.India],
      },
      {
        name: "Priyadarsini",
        role: "Senior IP Consultant",
        location: "India",
        image: [AssetPath.advisorteam.Priyadarsini],
        flag: [AssetPath.flag.India],
      },
    ],
  },
];

export default TeamMember;
