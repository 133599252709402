// Brand =============
import mabtreeLogoDark from "../assets/images/brand/mabtreelogo.svg";
import mabtreeLogoWhite from "../assets/images/brand/mabtreelogo-white.svg";
import mabtreeIcon from "../assets/images/brand/mabtree.svg";

// icons ==========
import facebookIcon from "../assets/images/icons/facebook.svg";
import linkedinIcon from "../assets/images/icons/linkedin.svg";

// pattern
import immunePatternDark from "../assets/images/pattern/immune-pattern-dark.svg";
import immunePatternGray from "../assets/images/pattern/immune-pattern-gray.svg";
import immunePatternColor from "../assets/images/pattern/immune-pattern-diff-color.svg";
import immuneCircle from "../assets/images/pattern/immune-circle.svg";

// images
import partners from "../assets/images/home/partners.svg";
import innovativeAntibodies from "../assets/images/innovative-antibodies.png";
import researchandInnovation from "../assets/images/pipeline-research.png";
import improvingLives from "../assets/images/improving-lives.png";
import aboutMabtree from "../assets/images/about-mabtree.png";
import career from "../assets/images/career.png";
import autoImmuneDisorder from "../assets/images/auto-immune-disorders.jpg";
// career page
import immunologyBioscience from "../assets/images/career/immunology-bioscience.jpg";
import Microbiology from "../assets/images/career/microbiology.jpg";
import Biotechnology from "../assets/images/career/biotechnology.jpg";

// Team Members
import AndhuvanRaj from "../assets/images/team/leadership-team/raj-andhuvan.jpg";
import Thibaut from "../assets/images/team/leadership-team/thibaut.jpg";
import MonicaAlexander from "../assets/images/team/leadership-team/monica-alexander.jpg";
import Jayachandran from "../assets/images/team/biosafety/souris.jpg";
import ChandniSood from "../assets/images/team/leadership-team/chandni-sood.jpg";
import chakra from "../assets/images/team/leadership-team/chakra.jpg";
import David from "../assets/images/team/leadership-team/david.jpg";
import WernerLang from "../assets/images/team/leadership-team/werner-lang.jpg";
import DarrenTaylor from "../assets/images/team/leadership-team/darren-taylor.jpg";
import Origene from "../assets/images/team/leadership-team/origene-nyanguile.jpg";
import Arun from "../assets/images/team/leadership-team/arun.png";
import Massimo from "../assets/images/team/leadership-team/massimo-nobile.jpg";
import Veda from "../assets/images/team/leadership-team/Veda.jpg";
import tataNageswaraRao from "../assets/images/team/leadership-team/tata-nageswara-rao.jpg";
import filippoOliveri from "../assets/images/team/leadership-team/filippo-oliveri.png";

// Bio safetyteam
import DineshKumar from "../assets/images/team/biosafety/dinesh-kumar.jpg";
import VasanthiSirugiri from "../assets/images/team/biosafety/vasanthi-sirugiri.jpg";
import Souris from "../assets/images/team/biosafety/souris.jpg";
import Ravi from "../assets/images/team/biosafety/ravi.jpg";
import sakthi from "../assets/images/team/biosafety/sakthi.png";

// advisor team
import alphonseLouisa from "../assets/images/team/advisors/alphonse-louisa.jpg";
import ChenthooriPugazendhi from "../assets/images/team/advisors/chenthoori-pugazendhi.jpg";
import Priyanka from "../assets/images/team/advisors/priyanka.jpg";
import Puja from "../assets/images/team/advisors/puja.jpg";
import shivani from "../assets/images/team/advisors/shivani.jpg";
import Priyadarsini from "../assets/images/team/advisors/priyadarsini.jpg";

// import country
import Germany from "../assets/images/country/germany.png";
import Switzerland from "../assets/images/country/switzerland.png";
import UnitedKingdom from "../assets/images/country/united-kingdom.png";
import India from "../assets/images/country/india.png";

// main banner
import banner1 from "../assets/images/banner/patient.jpg";
import banner2 from "../assets/images/banner/revolutionizing-treatment-with-monoclonal-antibodies.jpg";
import banner3 from "../assets/images/banner/bridging-the-gap-in-healthcare.jpg";
import banner4 from "../assets/images/banner/mabtree-biologics-ag.jpg";
import banner5 from "../assets/images/banner/innovating-for-a-healthier-tomorrow.jpg";
import banner6 from "../assets/images/banner/career.jpg";

const AssetPath = {
  leadershipTeam: {
    AndhuvanRaj,
    Thibaut,
    MonicaAlexander,
    Jayachandran,
    ChandniSood,
    chakra,
    David,
    WernerLang,
    DarrenTaylor,
    Origene,
    Arun,
    Massimo,
    Veda,
    tataNageswaraRao,
    filippoOliveri,
  },
  biosafetyteam: {
    DineshKumar,
    VasanthiSirugiri,
    Souris,
    Ravi,
    Puja,
    shivani,
    Priyadarsini,
    sakthi,
  },
  advisorteam: {
    alphonseLouisa,
    ChenthooriPugazendhi,
    Priyanka,
    shivani,
    Priyadarsini,
  },
  flag: { Germany, Switzerland, UnitedKingdom, India },
  brand: {
    mabtreeLogoDark,
    mabtreeLogoWhite,
    mabtreeIcon,
  },
  banner: { banner1, banner2, banner3, banner4, banner5, banner6 },
  icons: { facebookIcon, linkedinIcon },
  shapes: {},
  images: {
    partners,
    innovativeAntibodies,
    researchandInnovation,
    improvingLives,
    aboutMabtree,
    career,
    // career
    immunologyBioscience,
    Microbiology,
    Biotechnology,
    autoImmuneDisorder,
  },
  pattern: {
    immunePatternDark,
    immunePatternGray,
    immunePatternColor,
    immuneCircle,
  },
};

export default AssetPath;
