import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "../screens/LandingPage/Home";
import AboutUs from "../screens/AboutUs/AboutUs";
import Partnering from "../screens/Partnering/Partnering";
import Layout from "../Layout/Layout";
import Pipeline from "../screens/Pipeline/Pipeline";
import ResearchInnovation from "../screens/ResearchInnovation/ResearchInnovation";
import Careers from "../screens/Careers/Careers";
import Contact from "../screens/Contact/Contact";
import FAQ from "../screens/FAQ/FAQ";
import Privacy from "../screens/Privacy/Privacy";
import TermsandConditions from "../screens/TermsandConditions/TermsandConditions";
import Login from "../screens/Login/Login";
import PageNotFound from "../screens/PageNotFound/PageNotFound";
class InnerRoutes extends React.Component {
  render() {
    const {
      match: { url },
    } = this.props;
    return (
      <Layout {...this.props}>
        <Switch>
          <Route exact path={`/`} component={Home} />
          <Route path={`/about-us`} component={AboutUs} />
          <Route path={`/partnering`} component={Partnering} />
          <Route path={`/pipeline`} component={Pipeline} />
          <Route
            path={`/research-and-innovation`}
            component={ResearchInnovation}
          />
          <Route path={`/careers`} component={Careers} />
          <Route path={`/contact`} component={Contact} />
          <Route path={`/faq`} component={FAQ} />
          <Route path={`/privacy-policy`} component={Privacy} />
          <Route
            path={`/terms-and-conditions`}
            component={TermsandConditions}
          />
          <Route path={`/login`} component={Login} />
          <Route path={`*`} component={PageNotFound} />
        </Switch>
      </Layout>
    );
  }
}

export default InnerRoutes;
