import { Col, Row, Tabs } from "antd";
import React from "react";
import TeamMember from "./TeamMember";
import "./AboutUs.scss";
import noImage from "../../assets/images/team/leadership-team/malcolm-page.jpg";
import { Link } from "react-router-dom";
import { ArrowRight } from "iconsax-react";
import CTA from "../../Components/CTA/CTA";
import AssetPath from "../../AssetPath/AssetPath";
import PageTitle from "../../Components/PageTitle/PageTitle";

export default function AboutUs() {
  const tabItems = TeamMember.map((team, index) => ({
    label: team.Title,
    key: String(index + 1),
    children: (
      <Row
        gutter={[
          { xs: 15, sm: 15, md: 15, lg: 15 },
          { xs: 15, sm: 15, md: 15, lg: 15 },
        ]}
        align="middle"
        className="pb-3 "
      >
        {team.leadershipTeam.map((member, idx) => (
          <Col
            xs={{ span: 24 }}
            sm={{ span: 12 }}
            md={{ span: 8 }}
            lg={{ span: 6 }}
            key={idx}
          >
            <div className="team-list" data-aos="fade-up">
              <img src={member.image || noImage} alt={member.name} />
              <h3 className="text-black font-weight-semibold mb-1">
                {member.name}
              </h3>
              <div className="text-gray font-6 text-uppercase">
                {member.role}
              </div>
              {member.location && (
                <div className="location font-6">
                  <img src={member.flag} alt={member.location} />
                  {member.location}
                </div>
              )}
            </div>
          </Col>
        ))}
      </Row>
    ),
  }));

  return (
    <>
      <PageTitle
        mainTitle="About"
        longTitle="mAbTree Biologics"
        breadcrumbItems={[{ name: "About", path: "/about-us" }]}
        image={AssetPath.banner.banner2}
      />
      <section className="about-us spacing bg-white position-relative overflow-hidden">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="middle"
            justify="center"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 10 }}
            >
              <div
                className="width-80 highlight-title text-left mb-4"
                data-aos="fade-up"
              >
                <h2 className=" mb-3">Our Purpose</h2>
                <p>
                  Our core purpose is the creation and development of novel and
                  targeted antibodies with the aim of improving the lives of
                  patients with cancer and auto immune disorders.
                </p>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div data-aos="fade-up">
                <img
                  src={AssetPath.images.improvingLives}
                  alt="About Mabtree Biologics Ag"
                  className="w-100"
                />
              </div>
            </Col>
          </Row>
        </div>
        <div className="circle-pattern position-absolute start-150 top-150" />
      </section>
      <section className="our-history spacing bg-light-gray position-relative overflow-hidden">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 24, lg: 32 },
              { xs: 0, sm: 16, md: 24, lg: 20 },
            ]}
            align="middle"
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div className="position-relative z-index" data-aos="fade-up">
                <img
                  src={AssetPath.images.aboutMabtree}
                  alt="About Mabtree Biologics AG"
                  className=" w-100"
                />
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div
                className="highlight-title text-left mb-4 px-lg-3 position-relative z-index"
                data-aos="fade-up"
              >
                <h2>History</h2>
                <p>
                  From our start in 2019, our continued commitment to Oncology
                  and Immunology has given us purpose and a drive to improve the
                  lives of patients with cancer and autoimmune disorders. We
                  strive to achieve this goal by working together as one team
                  and building on our world-class research in antibodies to
                  expand our capabilities beyond the lab.
                </p>
                <p>
                  Our history has been powered by a dedication to antibody-based
                  therapeutics. It is this same spirit that will guide us into
                  the future.
                </p>
                <p>We are based in Switzerland and India.</p>
              </div>
            </Col>
          </Row>
        </div>
        <div className="mabtree-pattern  position-absolute end-0 bottom-0" />
      </section>

      <section className="our-team spacing bg-white">
        <div className="container">
          <Row justify="center" align="middle" className="pb-3">
            <Col
              xs={{ span: 24 }}
              sm={{ span: 12 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
            >
              <div
                className="highlight-title text-center mb-4"
                data-aos="fade-up"
              >
                <h2 className="mb-2">Our Team</h2>
              </div>
              <p className="lead text-center mb-0" data-aos="fade-up">
                We are a team of dedicated scientists and business leaders, with
                a passion and commitment to health care.
              </p>
            </Col>
          </Row>

          <Tabs defaultActiveKey="1" centered items={tabItems} />
        </div>
      </section>

      <CTA
        title="Become a part of our team"
        description="Here's your opportunity to join a unique, global company with an
                incredible, life-changing mission"
        linkText="Join Now"
        PageLink={"/careers"}
      />
    </>
  );
}
