import { Col, Row } from "antd";
import React from "react";
import "./Contact.scss";
import ContactDetails from "../../Layout/ContactDetails";
import ContactForm from "./ContactForm";
import PageTitle from "../../Components/PageTitle/PageTitle";
import AssetPath from "../../AssetPath/AssetPath";

export default function Contact() {
  return (
    <React.Fragment>
      <PageTitle
        mainTitle="Get"
        longTitle="in touch"
        mainDesc="Have a specific inquiry? Here's a few ways you can get in touch with us."
        breadcrumbItems={[{ name: "Contact Us", path: "/contact" }]}
        image={AssetPath.banner.banner2}
      />
      <section className="contact-support spacing bg-light-gray">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 22, lg: 22 },
              { xs: 0, sm: 16, md: 22, lg: 22 },
            ]}
            className="pb-3 "
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              data-aos="fade-up"
            >
              <div
                className="highlight-title contact-card text-left mb-2"
                data-aos="fade-up"
              >
                <h4 className="text-primary">Contact Support</h4>
                <p className="mb-2">
                  Expect a response from our Support Team within 24 hours.
                </p>
                <ul>
                  {ContactDetails.map((v, index) => (
                    <li key={index}>
                      <a href={v.link}>
                        <span>{v.icon}</span> {v.number}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              data-aos="fade-up"
            >
              <div
                className="highlight-title contact-card text-left mb-2"
                data-aos="fade-up"
              >
                <h4 className="mb-3 text-primary">Headquaters</h4>
                <h5 className="text-black">mAbTree Biologics AG</h5>
                <p className="mb-0">
                  <span className="d-block">Technologiepark Basel</span>
                  <span className="d-block">Hochbergerstrasse 60c</span>
                  <span className="d-block">4057 Basel, Switzerland.</span>
                </p>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 8 }}
              lg={{ span: 8 }}
              data-aos="fade-up"
            >
              <div
                className="highlight-title contact-card text-left mb-2"
                data-aos="fade-up"
              >
                <h4 className="mb-3 text-primary">Content Partnership</h4>
                <p>
                  To explore a partnership with mAbTree Biologics, reach out to
                  us at info@mabtree.com.
                </p>
              </div>
            </Col>
          </Row>
        </div>
      </section>

      <section className="contact-form-section oncology-immunology-banner position-relative">
        <div className="container">
          <Row
            gutter={[
              { xs: 0, sm: 16, md: 22, lg: 22 },
              { xs: 0, sm: 16, md: 22, lg: 22 },
            ]}
            justify={"middle"}
            align={"center"}
          >
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              data-aos="fade-up"
            >
              <div className="width-80">
                <h2 className="text-primary">Contact Form</h2>
                <p className="text-white">
                  Upon submitting your details through our contact form,
                  anticipate a reply from our Support Team within 24 hours.
                </p>
              </div>
            </Col>
            <Col
              xs={{ span: 24 }}
              sm={{ span: 24 }}
              md={{ span: 12 }}
              lg={{ span: 12 }}
              data-aos="fade-up"
            >
              <div className="form-section">
                <ContactForm />
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </React.Fragment>
  );
}
